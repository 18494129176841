import * as React from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Container,
  Heading,
  Text,
  Flex,
  Box,
  chakra
} from '@chakra-ui/react'
import { RichText } from 'prismic-reactjs'
import PageHero from '../components/PageHero/PageHero'
import Tetons from '../components/PageHero/Tetons'
import MobilePageHero from '../components/PageHero/MobilePageHero'
import { getImage } from 'gatsby-plugin-image'
import ToolBarCircles from '../components/Homepage/ToolBarCircles'
import NewsCardRack from '../components/NewsCards/NewsCardRack'
import NewsletterSignup from '../components/NewsletterSignup/NewsletterSignup'
import { CustomLink } from '../utils/customLink'
import MooseLottie from '../components/Footer/Moose/MooseLottie'


const IndexPage = ({ data }) => {
  if (!data) return null; 

  const document = data.prismicHomepage.data
  const backgroundImage = getImage(document.hero_image.localFile)
  const image = getImage(backgroundImage);

  return (
    <Layout>
      <Seo title="City of Driggs, Idaho"  />
      <PageHero 
        heroImage={image}
        photoCredit={document.hero_image.copyright}
      />
     <MobilePageHero 
        heroImage={image}
        photoCredit={document.hero_image.copyright}
      >
        <Flex w="100%" height={{base: "20vh", lg: "60vh"}} flexDirection="column" justifyContent="flex-end" paddingBottom="5%" alignItems="left">
        </Flex>
        <Tetons 
          boxWidth="45%"
        />
      </MobilePageHero>
      <Box position="relative" zIndex="1">
        <Flex w="100%" h="100%">
      <Container maxW="container.xl" mt="12" mb="36rem">
        
        <Box mb="12">
          <Heading as="h1" textTransform="uppercase" textAlign="center" fontSize="2.75rem" mb="4">
            {document.headline.text}
          </Heading>
          <Heading as="h2" textAlign="center" color="pale-sky.500" mb="8" fontSize="1.75rem">
            {document.subtitle.text}
          </Heading>
          <ToolBarCircles />
          <Text>
            <RichText render={document.intro_copy.richText} serializeHyperlink={CustomLink}/>
          </Text>
          <Box>
          <Heading as="h2" size="2xl" mt="24" mb="18" textAlign="center" textTransform="uppercase">
          Stay in the Know: <chakra.span color='pale-sky.500'>Recent City News</chakra.span>
          </Heading>
          </Box>
          <NewsCardRack />
          <NewsletterSignup />
        </Box>
      </Container>
      <MooseLottie />
      
      </Flex>
      </Box>
    </Layout>
  )
}

export const query = graphql`
{
  prismicHomepage {
    _previewable
    data {
      page_title {
        text
      }
      headline {
        text
      }
      subtitle {
        text
      }
      intro_copy {
        richText
      }
      hero_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        copyright
        alt
      }
    }
  }
  allPrismicNews(limit: 9, sort: {fields: data___date_posted, order: DESC}) {
    edges {
      node {
        uid
        url
        data {
          post_title {
            text
          }
          post_excerpt {
            text
          }
          featured_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.5
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
          }
          date_posted(formatString: "MMMM D, YYYY")
        }
      }
    }
  }
}
`


export default withPrismicPreview(IndexPage);